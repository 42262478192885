<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <div v-else class="contact">
      <!-- contact hero section -->
      <section class="contact__hero">
        <b-container>
          <b-row>
            <b-col cols="12" md="6">
              <h1 v-html="$prismic.asHtml(this.primary_title)" class="contact__title"></h1>
              <p v-html="$prismic.asHtml(this.primary_text)" class="main-text main-text--semi"></p>
            </b-col>
            <b-col class="text-center" cols="12" md="6">
              <img v-lazy="this.primary_image.url" class="img-fluid contact__image" :alt="this.primary_image.alt" />
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- contact form section -->
      <section class="contact__form">
        <b-container>
          <b-row>
            <b-col cols="12" md="5">
              <h5 v-html="$prismic.asHtml(this.company_name)" class="contact__subtitle"></h5>
              <div class="contact__text" v-html="$prismic.asHtml(this.company_address)"></div>
              <a :href="'mailto:'+ $prismic.richTextAsPlain(this.company_email)" class="contact__link">{{ $prismic.richTextAsPlain(this.company_email) }}</a>
              <div class="text-left">
                <a :href="this.twitter_url" class="contact__link"><img v-lazy="this.twitter_icon.url" class="contact__icon img-fluid" :alt="this.twitter_icon.alt" /></a>
              </div>
            </b-col>
            <b-col cols="12" md="7">
              <ContactForm :name_label="$prismic.richTextAsPlain(this.name_label)" :email_label="$prismic.richTextAsPlain(this.email_label)" :message_label="$prismic.richTextAsPlain(this.message_label)" :consent_text="$prismic.richTextAsPlain(this.consent_text)" :submit_button_text="$prismic.richTextAsPlain(this.submit_button_text)" :feedback_text="$prismic.richTextAsPlain(this.feedback_text)" />
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- aside section -->
      <AsideComponent />
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'ContactPage',
  components: {
    Spinner,
    ContactForm: () => import('@/views/ContactPage/components/ContactForm.vue'),
    AsideComponent: () => import('@/components/AsideComponent/AsideComponent.vue')
  },
  data: () => {
    return {
      cmsContentPending: false,
      primary_title: null,
      primary_text: null,
      primary_image: null,
      company_name: null,
      company_address: null,
      company_email: null,
      twitter_icon: null,
      twitter_url: null,
      name_label: null,
      email_label: null,
      message_label: null,
      consent_text: null,
      feedback_text: null
    }
  },
  methods: {
    async getContactPageContent () {
      await this.$prismic.client.getSingle('contactpage')
        .then((document) => {
          // primary/hero section
          this.primary_title = document.data.primary_title
          this.primary_text = document.data.primary_text
          this.primary_image = document.data.primary_image_url
          this.company_name = document.data.company_name
          this.company_address = document.data.company_address
          this.company_email = document.data.company_email
          this.twitter_icon = document.data.twitter_icon
          this.twitter_url = document.data.twitter_url.url
          // contact form section
          this.name_label = document.data.name_label
          this.email_label = document.data.email_label
          this.message_label = document.data.message_label
          this.consent_text = document.data.consent_text
          this.submit_button_text = document.data.submit_button_text
          this.feedback_text = document.data.feedback_text
          // meta title
          window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(document.data.meta_title)
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getContactPageContent()
  }
}
</script>

<style lang="scss" scoped>
  @import "./ContactPage.scss";
</style>
